<template>
  <div class="row">
    <div class="col-lg-12 col-md-8 col-sm-3">
      <KTPortlet>
        <template v-slot:body>
          <b-form class="kt-form" @submit.stop.prevent="onSubmit">
            <div class="text-center">
              <h3>{{ $t("AUTH.FORGOT.RESET_PASSWORD") }}</h3>
            </div>
            <br />
            <div
              role="alert"
              v-if="hide_field === false"
              class="alert alert-dark"
            >
              <div class="alert-text">
                <h5>{{ $t("AUTH.FORGOT.DESC") }}</h5>
              </div>
            </div>
            <div
              role="alert"
              v-if="errors.length"
              v-bind:class="{ show: errors.length }"
              class="alert fade alert-danger"
            >
              <div class="alert-text">
                <ul v-for="(error, i) in errors" :key="i">
                  <li>{{ error }}</li>
                </ul>
              </div>
            </div>
            <div role="alert" v-if="message !== null" class="alert alert-info">
              <div class="alert-text">
                {{ message }}
              </div>
            </div>
            <b-overlay :show="uploading" variant="transparent" rounded="sm">
              <b-row v-if="hide_field === false">
                <b-col>
                  <div role="group">
                    <label>
                      {{ $t("GENERAL.ENTER_YOUR_EMAIL_ADDRESS_HERE") }}:
                    </label>
                    <b-form-input
                      id="email_address"
                      name="email_address"
                      type="text"
                      v-model="$v.form.email_address.$model"
                      :state="validateState('email_address')"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-2-live-feedback">
                      {{
                        $t("VALIDATION.REQUIRED", {
                          name: $t("PROFILE.EMAIL_ADDRESS")
                        })
                      }}
                    </b-form-invalid-feedback>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col v-if="hide_field === false" class="kt-margin-t-5">
                  <b-button
                    type="submit"
                    id="kt_submit"
                    class="btn btn-primary btn-elevate kt-login__btn-primary"
                  >
                    {{ $t("AUTH.GENERAL.SUBMIT_BUTTON") }}
                  </b-button>
                </b-col>
                <b-col class="kt-margin-t-15">
                  <router-link
                    to="/login"
                    class="kt-link kt-login__link-forgot"
                  >
                    {{ $t("AUTH.FORGOT.RETURN_TO_LOGIN") }}
                  </router-link>
                </b-col>
              </b-row>
            </b-overlay>
          </b-form>
        </template>
      </KTPortlet>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";

import { validationMixin } from "vuelidate";
import { email, required } from "vuelidate/lib/validators";
import ApiService from "@/common/api.service";

export default {
  mixins: [validationMixin],
  data() {
    return {
      form: {
        email_address: null
      },
      errors: [],
      message: null,
      uploading: false,
      hide_field: false
    };
  },
  components: {
    KTPortlet
  },
  validations: {
    form: {
      email_address: {
        email,
        required
      }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      this.errors = [];
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      const email_address = this.$v.form.email_address.$model;
      this.uploading = true;
      ApiService.put("forgot-password", { email_address: email_address })
        .then(result => {
          this.uploading = false;
          this.hide_field = true;
          this.message = result.data.message;
        })
        .catch(({ response }) => {
          this.uploading = false;
          this.errors = response.data.error;
        });
    }
  }
};
</script>
